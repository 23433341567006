jQuery(function ($) {
    
    if($("#cta-button").length) {
        $.fn.isSticky = function() {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
        
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).innerHeight();
        
            //return elementBottom > viewportTop && elementTop < viewportBottom;
            
            return elementBottom > viewportTop && elementBottom + 25 <= viewportBottom;
        };
        
        $.fn.isInViewport = function() {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
        
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).innerHeight();
        
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };
        
        $(window).on('resize scroll', function() {
            if ($('#cta-button').isSticky()) {
                $("#cta-button").css("position", "fixed");
                $("#cta-button").css("padding", "15px 0");
            } else {
                
            }
            
            if ($("#action-form").isInViewport()) {
                $("#cta-button").css("display", "none");
            } else {
                $("#cta-button").css("display", "block");
            }
        });
    }
    
    if($("#twitterShareBtn").length) {
        $("#twitterShareBtn").hover(function(){
          $("#twitterShareBtn .js-nc-int-icon").toggleClass("nc-int-icon-state-b");
          $("#twitterShareBtn svg").css("background-color", "#000000");
        }, function(){
          $("#twitterShareBtn .js-nc-int-icon").toggleClass("nc-int-icon-state-b");
          $("#twitterShareBtn svg").css("background-color", "#00A0BB");
        });
    }
    if($("#facebookShareBtn").length) {
        $("#facebookShareBtn").hover(function(){
          $("#facebookShareBtn .js-nc-int-icon").toggleClass("nc-int-icon-state-b");
          $("#facebookShareBtn svg").css("background-color", "#000000");
        }, function(){
          $("#facebookShareBtn .js-nc-int-icon").toggleClass("nc-int-icon-state-b");
          $("#facebookShareBtn svg").css("background-color", "#00A0BB");
        });
    }
    
    /**
     * Wait for an element before resolving a promise
     * @param {String} querySelector - Selector of element to wait for
     * @param {Integer} timeout - Milliseconds to wait before timing out, or 0 for no timeout              
     */
    function waitForElement(querySelector, timeout=0){
        const startTime = new Date().getTime();
        return new Promise((resolve, reject)=>{
            const timer = setInterval(()=>{
                const now = new Date().getTime();
                if(document.querySelector(querySelector)){
                    clearInterval(timer);
                    resolve();
                }else if(timeout && now - startTime >= timeout){
                    clearInterval(timer);
                    reject();
                }
            }, 15);
        });
    }
    
    waitForElement("label[for='form-zip_code']", 3000).then(function(){
        $("label[for='form-zip_code']").text("Postal Code *");
    }).catch(()=>{
        //console.log("element did not load in 3 seconds");
    });
    
    // open video modals on click
    if($('.play-video-button').length) {
        $('.play-video-button').each(function() {
            var modal = $(this).attr('data-target');
            var video = $(this).attr('data-video');
            var modal = document.getElementById(modal);
            var mID = $(modal).attr('id');
            var mSRC = $("#"+mID+" .modal-body iframe").attr('src');
            $(this).click(function() {
                $(modal).modal("show");
            })
            $(modal).on("shown.bs.modal", function (e) {
                $("#"+mID+" .modal-body iframe").attr('src', mSRC+'&autoplay=1');
            });
            $(modal).on("hide.bs.modal", function (e) {
                $("#"+mID+" .modal-body iframe").attr('src', '');
            });
        })
    }
    
    if($('.play-video-button-header').length || $('.watch-share button').length) {
        
        var headerMsrc = $("#video-modal .modal-body iframe").attr('src');
        
        $('.play-video-button-header, .watch-share button').click(function() {
            $('#video-modal').modal("show");
        })
        $('#video-modal').on("shown.bs.modal", function (e) {
            $("#video-modal .modal-body iframe").attr('src', headerMsrc+'&autoplay=1');
        });
        $('#video-modal').on("hide.bs.modal", function (e) {
            $("#video-modal .modal-body iframe").attr('src', '');
        });
    }
    
    // Sort out masthead on various pages
    if($('.top-masthead').length) {
        //console.log("masthead needs adjusted");
        var height = $('.top-masthead').outerHeight();
        //console.log("it's height is: " + height);
        $('.top-masthead').css('margin-bottom','-'+height+'px');
    }
    
    /**
     * Replace all SVG images with inline SVG
     */
    $('img.svg').each(function(){
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });
    
    $('#cta .cta-background > .col-md-6:not(.default-image)').each(function() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            
        } else {
            var background = $(this).css('background');
            $(this).css('background','linear-gradient(179deg, #070606 0%, #080808F7 0%, #000000 1%, #FBFBFB04 20%, #FFFFFF00 20%),'+background);
        }
    })

}); // jQuery End